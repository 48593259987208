.canvas-container {
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 2rem; 
    min-width: fit-content;
    min-height: fit-content;

    #bootlogo {
        -webkit-box-shadow: 0px 0px 20px 8px rgba(127, 131, 187, 1);
        -moz-box-shadow: 0px 0px 20px 8px rgba(127, 131, 187, 1);
        box-shadow: 0px 0px 20px 8px rgba(127, 131, 187, 1);
    }
}